<template>
  <div class="contenedor">
    <Navbar />
    <v-container>
      <iframe :src="url" frameborder="0"></iframe>
    </v-container>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Importante!!!
        </v-card-title>

        <v-card-text class="pa-4">
          <h3>
            Bienvenidos al Censo Policial 2022. Le recordamos que una vez
            finalizada la encuesta debera dar cierre a la sesión mediante el
            botón "TERMINAR CENSO" ubicado en la esquina superior derecha de la
            pantalla.
          </h3>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Cerrar Ventana
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Navbar from "../components/Navbar.vue";

export default {
  components: {
    Navbar,
  },
  data() {
    return {
      dialog: true,
      url: "https://docs.google.com/forms/d/e/1FAIpQLSd1zFeBnFyJO44V71PxJgBYXGTLH8-Qf6KNAcJIArhQvPjHQw/viewform",
      frame: {},
    };
  },
  created() {
    if (this.getLogin) {
      this.$router.push("/").catch(() => {});
    }
  },
  computed: {
    ...mapGetters(["getLogin"]),
  },
  methods: {},
};
</script>

<style>
.contenedor {
  width: 100%;
  min-height: 100vh;
  background-image: url("../assets/4.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

iframe {
  margin-top: 7vh;
  width: 100%;
  height: 100vh;
}
</style>
