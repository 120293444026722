<template>
  <div class="contenedor">
    <v-container>
         <v-row>
          <v-col cols="12">
            <div class="contenedor__login">
      
    <h1 >Bienvenidos<br>Censo Policial 2022</h1>
    <div>
      <v-card
    class="mx-auto pa-2"
    max-width="344"

  >
    <v-card-text>
      <div class="d-flex align-center" style="gap:20px">
        <p class="text-h4">
        Iniciar Sesion
      </p><img src="@/assets/policia.png" alt="" height="80">
      </div>
      
       <div class="text--primary">
        <v-form ref="form" action v-model="valid" lazy-validation >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          
        >
          <v-text-field
            v-model="dni"
            :rules="dniRules"
            :counter="8"
            label="D.N.I (sin puntos)"
            type="number" 
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          
        >
          <v-text-field
            v-model="credencial"
            :rules="credencialRules"
            :counter="6"
            label="Credencial"
            required
            type="password" 
          ></v-text-field>
        </v-col>

        
      </v-row>
    </v-container>
  </v-form>
      </div>
    </v-card-text>
    <v-snackbar
      v-model="dialogErr"
      :timeout="timeout"
      rounded="pill"
      absolute
      bottom
      color="red accent-2"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="dialogErr = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <v-card-actions>
      <v-btn
      rounded
      :loading="loading"
      :disabled="loading"
      block
      color="primary"
      @click="submit"
    >
      <v-icon left >
        mdi-email-arrow-right
      </v-icon>
      Enviar
    </v-btn>
    </v-card-actions>

    
  </v-card>
    </div>
    <img src="@/assets/gob.png" alt="" height="50">
    </div>

          </v-col>
         </v-row>
    </v-container>
    
    
      
  </div>
</template>

<script>
  // $router.push({ name: 'About' })
  import axios from "axios";
  // import { mapGetters } from "vuex";
export default {
    data:()=>({
      text: 'Dni o Credencial Incorrectos',
      timeout: 3000,
      dialogErr:false,
      loading: false,
      dni:"",
      credencial:"",
      valid: false,
      dniRules: [
      v => !!v || "Ingrese el  D.N.I",
      v => (v && v.length >= 8) || "Ingrese un dni valido",
        ],
    credencialRules: [
      v => !!v || "Ingrese la Credencial",
      v => (v && v.length >= 4) || "Ingrese una credencial valida"
    ],
    }),
  //   computed: {
  //   ...mapGetters(["getLogin"]),
  // },
  // mounted(){
  //  console.log(this.getLogin)
  // },
    methods:{
       async  submit(){
             if(this.$refs.form.validate()){
              console.log("validado")
              // let n=this.dni
              this.loading= true
              
              try{
                await axios
        .post(`https://siis.electro3.com.ar/censo/api/v1/login/`,{ Dni: `${this.dni}`,CredencialPoli:`${this.credencial}` })
        .then((res) => {
          // this.respuesta = res.data[0].estado;
          let respuesta = res.data.response[0];
          
            
          console.log("respuesta>>>>>", respuesta);
          
          this.$store.dispatch("logueo", respuesta.Loguear);
          this.$store.dispatch("guardaDato", respuesta);
          
          if(!respuesta.Loguear){
            this.$router.push('/about').catch(()=>{});
          }else{
            this.dialogErr=true
            this.dni=""
            this.credencial=""

          }
          
          
        })
        
              }catch(e){
                console.log(e)

              }finally{
                this.loading=false
              }

             }
         }
    }

}
</script>

<style>

    .contenedor{
        width: 100%;
        min-height: 100vh;
        background-image: url('../assets/4.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        display: flex;
        justify-content: center;
        align-items: center;
  
    }
    .contenedor__login{
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 5vh;


    }
    .contenedor__login h1{
      text-align: center;
      font-size: 2.3rem;
      color: #8E8E8E;
      font-family: "Roboto", sans-serif !important;
      
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.0073529412em !important;
    font-family: "Roboto", sans-serif !important;

      
      
    }

    @media screen and (max-width: 650px) {
      .contenedor__login h1{
      text-align: center;
      font-size: 2rem;
      color: #8E8E8E;
      
      
    }
    .text-h4{
      font-size: 2rem !important; 
    }
    
}

.custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

</style>