<template>
    <div>
      <v-app-bar app  dark color="light-blue darken-4">
       
        <v-app-bar-title class="hidden-sm-and-down"
          ><h1>Policia de San Juan</h1></v-app-bar-title
        >
         
        <v-spacer></v-spacer>
        
       
        <v-btn color="orange darken-4" class="ml-4" @click="salir()"> Salir </v-btn>
      </v-app-bar>
  
     
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import axios from "axios";

  export default {
    data() {
      return {
        menu: false,
      };
    },
    methods: {
      
     async salir() {
        // let salirLogin = false;
        // this.$store.dispatch("habilitarAdmin", salirLogin);
        console.log(this.getDatos)
        try{
                await axios
        .post(`https://siis.electro3.com.ar/censo/api/v1/send-form/`,this.getDatos)
        .then((res) => {
          // this.respuesta = res.data[0].estado;
          
            
          
            
          console.log("respuesta>>>>>", res);
          
          
          
          
        })
        
              }catch(e){
                console.log(e)

              }
        this.$router.push("/").catch(() => {});
      },
    },
    computed: {
      ...mapGetters(["getDatos"]),
    },
  };
  </script>
  
  <style lang="scss" scoped>
  a {
    text-decoration: none;
    color: white !important;
  }
  #items {
    text-decoration: none;
    color: rgb(26, 3, 3) !important;
    margin: 5px;
    font-size: 15px;
  }
  </style>